import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MDXPage from '../components/MDXPage';
import PageMeta from '../components/PageMeta';
import Section from '../components/Section';
import Link from '../components/Link';
import StaticNavbar from '../components/StaticNavbar.mdx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = MDXPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageMeta title="Not Found" mdxType="PageMeta" />
    <StaticNavbar mdxType="StaticNavbar" />
    <Section className="mt-5 pt-5 mb-5 pb-5 text-center" mdxType="Section">
  <h3>Not Found</h3>
  <p>We couldn&apos;t find the page you&apos;re looking for.</p>
  <Link href="/" mdxType="Link">Back to Overview</Link>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      